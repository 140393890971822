import PropTypes from 'prop-types';
import React from 'react';

function LoadingPage({ hide }) {
  return (
    !hide && (
      <div className="fixed inset-0 bg-[#F2F5FF] grid place-items-center z-[9999999999]">
        <span className="loader" />
      </div>
    )
  );
}

LoadingPage.propTypes = {
  hide: PropTypes.bool
};

export default LoadingPage;
