import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

// UI
import LoadingPage from 'ui/LoadingPage';
// Ajax
import { loginGuestUser } from 'ajax/guestAuth';
// State
import { profileState } from 'state';

const LazyGuestBookingPage = React.lazy(() => import('components/Booking/GuestCreate'));

function GuestBookingPage() {
  const [, setProfile] = useRecoilState(profileState);
  // Login as Guest User
  const guestLoginHandler = () => {
    loginGuestUser().then((res) => {
      if (res.status === 200 && res.requestProfile?.status === 200) {
        setProfile(res.requestProfile.data);
        return setIsLoggedIn(true);
      }
      if (res.status === 401) return navigate('/login');
      return toast.error('Something went wrong, please try again later.');
    });
  };

  // Loggin In Guest
  useEffect(() => {
    guestLoginHandler();
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      <React.Suspense fallback={<span />}>{isLoggedIn && <LazyGuestBookingPage />}</React.Suspense>
    </>
  );
}

export default GuestBookingPage;

export function Head() {
  return <title>Create Booking - Veezu</title>;
}
