import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const url = process.env.GATSBY_API_ENDPOINT;

const axiosInstance = axios.create({
  baseURL: url,
  headers: {
    'Content-type': 'application/json'
  }
});

export async function getGuestLoginCredentials() {
  try {
    const request = await axiosInstance.get('/guest');

    return request;
  } catch (error) {
    return error.request;
  }
}

export async function loginGuestUser() {
  const fpPromise = FingerprintJS.load();
  const fid = await fpPromise.then((fp) => fp.get());
  const { visitorId } = fid;
  try {
    const request = await axios(`${url}/authenticate/guest`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Fingerprint: visitorId
      },
      data: {}
    });
    let requestProfile = null;
    if (request.status === 200) {
      localStorage.setItem('veezu_guest_token', request.data.token);
      requestProfile = await axios.get(`${url}/profile`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${request.data.token}`
        },
        data: {}
      });
      if (requestProfile.status !== 200) {
        requestProfile = null;
      }
    }
    return { ...request, requestProfile };
  } catch (error) {
    return error.request;
  }
}
